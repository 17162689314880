import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectPageName } from 'common/redux/appController/selectors';
import { PAGE_TYPE } from 'config/constants/routerName';
import { WidgetsColumnPropsType } from 'desktop/components/FirstPageWithWidgetsByTop/WidgetsColumn';

import { TravelWidgetsHome } from './components/TravelWidgetsHome';
import { TravelWidgetsTopic } from './components/TravelWidgetsTopic';

/**
 * Список виджетов для второй колонки для вертикали Путешествия
 * @param puids – объект пуидов для рекламы
 */
export const TravelWidgets = memo(({ puids }: WidgetsColumnPropsType) => {
  const pageName = useSelector(selectPageName);

  switch (pageName) {
    case PAGE_TYPE.home:
      return <TravelWidgetsHome puids={puids} />;
    case PAGE_TYPE.topic:
      return <TravelWidgetsTopic puids={puids} />;

    default:
      return <TravelWidgetsHome puids={puids} />;
  }
});
